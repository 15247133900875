import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Link } from "react-scroll";
import { useScreenSize } from "../hooks/useScreenSize";
import { breakpoints } from "../theme/mediaQuery";
import { NavMenuMobile } from "../components/NavMenuMobile";
import { NavMenuDesktop } from "../components/NavMenuDesktop";
import { useSectionsContext } from "../context/SectionsContext";
import LangSwitch from "../components/LangSwitch";

const Nav = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { width } = useScreenSize();
  const { featured: sections } = useSectionsContext();
  const closeNavMenu = () => setIsOpen(false);

  useEffect(() => {
    if (width > breakpoints[1]) {
      closeNavMenu();
    }
  }, [width]);

  return (
    <nav className="flex nav z">
      <Wrapper>
        <BurgerButton
          className={isOpen ? "is-open" : ""}
          onClick={() => setIsOpen(!isOpen)}
          type="button"
        >
          <Burger />
        </BurgerButton>
        <List>
          {sections.map(section => (
            <ListItem key={section.id}>
              <MenuLink
                duration={500}
                offset={-80}
                smooth
                to={section.sectionId}
              >
                {section.text}
              </MenuLink>
            </ListItem>
          ))}
        </List>
      </Wrapper>
      <NavMenuMobile isOpen={isOpen} closeNavMenu={closeNavMenu} />
      {/* <NavMenuDesktop /> */}
      <LangSwitch />
    </nav>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

const Burger = styled.span`
  position: relative;
  display: block;
  width: 30px;
  height: 3px;
  margin: 0 auto;
  background: ${({ theme }) => theme.colors.apple};
  transition: all 0.275s;
  border-radius: 2px;

  &:after,
  &:before {
    content: "";
    display: block;
    height: 100%;
    background-color: inherit;
    transition: all 0.275s;
    border-radius: 2px;
  }

  &:after {
    width: 100%;
    transform: translateY(-9px);
  }

  &:before {
    width: 65%;
    float: left;
    transform: translateY(9px);
  }
`;

const BurgerButton = styled.button`
  background: none;
  border: 0;
  cursor: pointer;
  pointer-events: all;
  padding: 1rem 0;

  &:focus {
    outline: none;
  }

  &.is-open {
    ${Burger} {
      transform: rotate(-45deg);
      &:before {
        width: 100%;
        transform: translateY(0px) rotate(90deg);
      }
      &:after {
        transform: translateY(-12px) translateX(10px);
        opacity: 0;
      }
    }
  }

  ${({ theme }) => `${theme.mq[1]} {
    display: none;
  }`}
`;

const List = styled.ul`
  display: none;
  margin: 0;
  list-style: none;
  padding: 0;
  ${({ theme }) => `${theme.mq[1]} {
    display: inline-flex;
  }`}
`;

const ListItem = styled.li`
  color: ${({ theme }) => theme.colors.white};
`;

const MenuLink = styled(Link)`
  margin: 0 20px;
  color: ${({ theme }) => theme.colors.white};
  font-size: 1rem;
  cursor: pointer;
  pointer-events: all;
`;

export { Nav };
