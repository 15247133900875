import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import solucionesBackImg from "../assets/images/soluciones-back.png";
import asistentesVirtualesIcon from "../assets/images/asistentes-virtuales.svg";
import conversationalAnalyticsIcon from "../assets/images/conversational-analytics.svg";
import gestionDeConocimientoIcon from "../assets/images/gestion-de-conocimiento.svg";
import procesamientoDocumentosIcon from "../assets/images/procesamiento-de-documentos.svg";

const Solutions = ({ translate }) => (
  <>
    <BackImgContainer className="back-image">&nbsp;</BackImgContainer>
    <div className="wrapper title">
      <h3>{translate("web_cognitiva_solutions_title")}</h3>
      <div className="white-line">&nbsp;</div>
      <p>{translate("web_cognitiva_solutions_txt")}</p>
    </div>
    <div className="wrapper flex">
      <div className="soluciones-box bg-darkgrey">
        <div className="soluciones-box-img">
          <img src={asistentesVirtualesIcon} alt="Icono asistentes virtuales" />
        </div>
        <span className="soluciones-box-title">
          {translate("web_cognitiva_solutions_card_01_title")}
        </span>
        {translate("web_cognitiva_solutions_card_01_desc")}
        <b>{translate("web_cognitiva_solutions_card_01_highlight")}</b>
      </div>
      <div className="soluciones-box">
        <div className="soluciones-box-img">
          <img
            src={conversationalAnalyticsIcon}
            alt="Icono conversational analytics"
          />
        </div>
        <span className="soluciones-box-title">
          {translate("web_cognitiva_solutions_card_02_title")}
        </span>
        <b>{translate("web_cognitiva_solutions_card_02_highlight")}</b>
        {translate("web_cognitiva_solutions_card_02_desc")}
      </div>
      <div className="soluciones-box">
        <div className="soluciones-box-img">
          <img
            src={gestionDeConocimientoIcon}
            alt="Icono gestion de conocimiento"
          />
        </div>
        <span className="soluciones-box-title">
          {translate("web_cognitiva_solutions_card_03_title")}
        </span>
        {translate("web_cognitiva_solutions_card_03_desc_01")}
        <b>{translate("web_cognitiva_solutions_card_03_highlight")}</b>
        {translate("web_cognitiva_solutions_card_03_desc_02")}
      </div>
      <div className="soluciones-box bg-darkgrey">
        <div className="soluciones-box-img">
          <img
            src={procesamientoDocumentosIcon}
            alt="Icono procesamiento de documentos"
          />
        </div>
        <span className="soluciones-box-title">
          {translate("web_cognitiva_solutions_card_04_title")}
        </span>
        <b>{translate("web_cognitiva_solutions_card_04_highlight")}</b>
        {translate("web_cognitiva_solutions_card_04_desc")}
      </div>
    </div>
  </>
);

Solutions.propTypes = {
  translate: PropTypes.func.isRequired
};

const BackImgContainer = styled.div`
  background-image: url(${solucionesBackImg});
`;

export { Solutions };
