export const documentObject = (typeof document !== 'undefined' && document) || {
  documentElement: {
    style: {
      setProperty() {
        console.log('document.documentElement.style.setProperty()');
      }
    }
  },
  createElement() {
    console.log('document.createElement()');
  },
  getElementById() {
    console.log('document.getElementById()');
    return {
      appendChild() {
        console.log('document.getElementById().appendChild');
      },
      style: {}
    };
  }
};
