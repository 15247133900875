import { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { documentObject } from '../globals/document';

const navRoot = documentObject.getElementById('desktop-nav-portal');

class DesktopNavPortal extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired
  };

  constructor(props) {
    super(props);
    this.element = documentObject.createElement('div');
    if (navRoot) {
      navRoot.style.position = 'fixed';
      navRoot.style.zIndex = 500;
      navRoot.style.top = 0;
      navRoot.style.left = 0;
      navRoot.style.width = '100vw';
      navRoot.style.pointerEvents = 'none';
    }
  }

  componentDidMount() {
    navRoot.appendChild(this.element);
  }

  componentWillUnmount() {
    navRoot.removeChild(this.element);
  }

  render() {
    const { children } = this.props;
    if (typeof document !== `undefined`) {
      return ReactDOM.createPortal(children, this.element);
    }
    return null;
  }
}

export { DesktopNavPortal };
