import React from "react";

import teamImg from "../assets/images/equipo.png";

const Team = ({ translate }) => {
  return (
    <div className="wrapper flex">
      <div className="equipo-img">
        <img src={teamImg} alt="Imagen equipo" />
      </div>
      <div className="equipo-text">
        <div className="title">{translate("web_cognitiva_team_title")}</div>
        <div className="blue-line">&nbsp;</div>
        <p>{translate("web_cognitiva_team_desc")}</p>
        <div className="equipo-text-item flex">
          <div className="number">1</div>
          <div className="description">
            {translate("web_cognitiva_team_item_01_desc")}
          </div>
        </div>
        <div className="equipo-text-item flex">
          <div className="number">2</div>
          <div className="description">
            {translate("web_cognitiva_team_item_02_desc")}
          </div>
        </div>
        <div className="equipo-text-item flex">
          <div className="number">3</div>
          <div className="description">
            {translate("web_cognitiva_team_item_03_desc")}
          </div>
        </div>
        <div className="equipo-text-item flex number4">
          <div className="number">4</div>
          <div className="description">
            {translate("web_cognitiva_team_item_04_desc")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
