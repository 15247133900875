import React from "react";
import PropTypes from "prop-types";
import chevronsDown from "../assets/images/chevrons-down.svg";
import videoBack from "../assets/images/video.mp4";

const Inicio = ({ translate }) => (
  <>
    <div className="h1-box">
      <h1>
        {translate("web_cognitiva_top_level_title")}
        <span className="green">
          {translate("web_cognitiva_top_level_title_highlight")}
        </span>
      </h1>
      <p>{translate("web_cognitiva_top_level_txt")}</p>
      <div className="chevrons">
        <img src={chevronsDown} alt="Scroll down" />
      </div>
    </div>
    <div className="video">
      <video autoPlay loop muted>
        <source src={videoBack} type="video/mp4" />
      </video>
    </div>
  </>
);

Inicio.propTypes = {
  translate: PropTypes.func.isRequired
};

export { Inicio };
