import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

const Policy = ({ translate, closeButtonClick }) => {
  return (
    <Wrapper>
      <Header>
        <h2>{translate("web_cognitiva_bottom_privacy")}</h2>
        <CloseButton onClick={closeButtonClick}>X</CloseButton>
      </Header>
      <p>
        {translate("web_cognitiva_privacy_policy_02")}{" "}
        {translate("web_cognitiva_privacy_policy_03")}{" "}
        {translate("web_cognitiva_privacy_policy_05")}
      </p>
      <p>{translate("web_cognitiva_privacy_policy_06")}</p>
      <OrderedList>
        <li>
          <Title>{translate("web_cognitiva_privacy_policy_07")}</Title>
          <p>{translate("web_cognitiva_privacy_policy_08")}</p>
          <OrderedList>
            <li>
              <p>{translate("web_cognitiva_privacy_policy_09")}</p>
            </li>
            <li>
              <p>{translate("web_cognitiva_privacy_policy_10")}</p>
            </li>
            <li>
              <p>{translate("web_cognitiva_privacy_policy_11")}</p>
            </li>
            <li>
              <p>{translate("web_cognitiva_privacy_policy_12")}</p>
            </li>
          </OrderedList>
          <p>{translate("web_cognitiva_privacy_policy_13")}</p>
        </li>

        <li>
          <Title>{translate("web_cognitiva_privacy_policy_14")}</Title>
          <p>{translate("web_cognitiva_privacy_policy_15")}</p>
          <p>{translate("web_cognitiva_privacy_policy_16")}</p>
          <p>{translate("web_cognitiva_privacy_policy_17")}</p>
        </li>

        <li>
          <Title>{translate("web_cognitiva_privacy_policy_18")}</Title>
          <p>{translate("web_cognitiva_privacy_policy_19")}</p>
          <OrderedList>
            <li>
              <p>{translate("web_cognitiva_privacy_policy_20")}</p>
            </li>
            <li>
              <p>{translate("web_cognitiva_privacy_policy_21")}</p>
            </li>
            <li>
              <p>{translate("web_cognitiva_privacy_policy_22")}</p>
            </li>
            <li>
              <p>{translate("web_cognitiva_privacy_policy_23")}</p>
            </li>
            <li>
              <p>{translate("web_cognitiva_privacy_policy_24")}</p>
            </li>
          </OrderedList>
        </li>

        <li>
          <Title>{translate("web_cognitiva_privacy_policy_25")}</Title>
          <p>{translate("web_cognitiva_privacy_policy_26")}</p>
          <p>{translate("web_cognitiva_privacy_policy_27")}</p>
        </li>

        <li>
          <Title>{translate("web_cognitiva_privacy_policy_28")}</Title>
          <p>{translate("web_cognitiva_privacy_policy_29")}</p>
          <p>{translate("web_cognitiva_privacy_policy_30")}</p>

          <OrderedList>
            <li>
              <p>{translate("web_cognitiva_privacy_policy_31")}</p>
            </li>
            <li>
              <p>{translate("web_cognitiva_privacy_policy_32")}</p>
            </li>
            <li>
              <p>{translate("web_cognitiva_privacy_policy_33")}</p>
            </li>
            <li>
              <p>{translate("web_cognitiva_privacy_policy_34")}</p>
            </li>
          </OrderedList>
          <p>{translate("web_cognitiva_privacy_policy_35")}</p>
          <p>{translate("web_cognitiva_privacy_policy_36")}</p>
          <p>{translate("web_cognitiva_privacy_policy_37")}</p>
          <p>{translate("web_cognitiva_privacy_policy_38")}</p>
        </li>

        <li>
          <Title>{translate("web_cognitiva_privacy_policy_39")}</Title>
          <p>{translate("web_cognitiva_privacy_policy_40")}</p>
          <p>{translate("web_cognitiva_privacy_policy_41")}</p>
        </li>

        <li>
          <Title>{translate("web_cognitiva_privacy_policy_22")}</Title>
          <p>{translate("web_cognitiva_privacy_policy_43")}</p>
          <p>{translate("web_cognitiva_privacy_policy_44")}</p>
          <p>{translate("web_cognitiva_privacy_policy_45")}</p>
        </li>

        <li>
          <Title>{translate("web_cognitiva_privacy_policy_46")}</Title>
          <p>{translate("web_cognitiva_privacy_policy_47")}</p>
          <p>{translate("web_cognitiva_privacy_policy_48")}</p>
          <p>{translate("web_cognitiva_privacy_policy_49")}</p>
        </li>

        <li>
          <Title>{translate("web_cognitiva_privacy_policy_50")}</Title>
          <p>{translate("web_cognitiva_privacy_policy_51")}</p>
        </li>
      </OrderedList>
      <Header>
        <p>{translate("web_cognitiva_bottom_rights")}</p>
        <BottomCloseButton onClick={closeButtonClick}>
          {translate("web_cognitiva_privacy_policy_close")}
        </BottomCloseButton>
      </Header>
    </Wrapper>
  );
};

Policy.propTypes = {
  translate: PropTypes.func.isRequired
};

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2000;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
  font-size: 1rem;
  padding: 20px;
  margin: 20px;
  box-shadow: 2px 2px 5px 5px rgba(0, 0, 0, 0.2);

  p {
    line-height: 150%;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CloseButton = styled.button`
  border: none;
  font-size: 2rem;
  cursor: pointer;
  background-color: transparent;
`;

const BottomCloseButton = styled.button`
  padding: 15px 30px;
  background-color: ${({ theme }) => theme.colors.black};
  text-transform: uppercase;
  border: 0;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
`;

const Title = styled.h4`
  font-weight: bold;
  font-size: 1em;
`;

const OrderedList = styled.ul`
  padding-left: 15px;

  li {
    list-style-type: none;
  }
`;

export { Policy };
