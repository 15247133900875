import React from "react";
import { Global, css } from "@emotion/core";
import fontPrimaryMediumWoff from "../assets/fonts/GothamNarrow-Medium.woff";
import fontPrimaryMediumWoff2 from "../assets/fonts/GothamNarrow-Medium.woff2";
import fontPrimaryBoldWoff from "../assets/fonts/GothamNarrow-Bold.woff";
import fontPrimaryBoldWoff2 from "../assets/fonts/GothamNarrow-Bold.woff2";
import fontPrimaryBlackWoff from "../assets/fonts/GothamNarrow-Black.woff";
import fontPrimaryBlackWoff2 from "../assets/fonts/GothamNarrow-Black.woff2";
import redesigncss from "../styles/redesigncss";

const GlobalStyles = props => (
  <Global
    styles={css`
      @font-face {
        font-family: "Gotham";
        src: url(${fontPrimaryMediumWoff2}) format("woff2"),
          url(${fontPrimaryMediumWoff}) format("woff");
        font-weight: 500;
        font-style: normal;
      }

      @font-face {
        font-family: "Gotham";
        src: url(${fontPrimaryBoldWoff2}) format("woff2"),
          url(${fontPrimaryBoldWoff}) format("woff");
        font-weight: 700;
        font-style: normal;
      }

      @font-face {
        font-family: "Gotham";
        src: url(${fontPrimaryBlackWoff2}) format("woff2"),
          url(${fontPrimaryBlackWoff}) format("woff");
        font-weight: 900;
        font-style: normal;
      }

      * {
        box-sizing: border-box;
      }

      html {
        line-height: 1.15;
      }

      body {
        margin: 0;
      }

      button,
      input,
      optgroup,
      select,
      textarea {
        font-family: inherit;
        font-size: 100%;
        line-height: 1.15;
        margin: 0;
      }

      ${redesigncss}
    `}
    {...props}
  />
);

export { GlobalStyles };
