import React from "react";
import { Link } from "react-scroll";
import styled from "@emotion/styled";

import logo from "../assets/images/brand.svg";

const Logo = () => (
  <div className="brand z">
    <LogoLink duration={500} offset={-80} smooth to="section-header">
      <img src={logo} alt="Cognitiva by Etermax Labs" />
    </LogoLink>
  </div>
);

const LogoLink = styled(Link)`
  cursor: pointer;
  pointer-events: all;
`;

export { Logo };
