import { useState, useEffect } from 'react';
import { windowObject } from '../globals/window';

const useScreenSize = () => {
  const [size, setSize] = useState({ height: windowObject.innerHeight, width: windowObject.innerWidth });

  useEffect(() => {
    const handleResize = () => setSize({ height: windowObject.innerHeight, width: windowObject.innerWidth });
    windowObject.addEventListener('resize', handleResize);
    return () => windowObject.removeEventListener('resize', handleResize);
  }, []);

  return size;
};

export { useScreenSize };
