import React, { createContext, useContext, useEffect, useState } from "react";

const SectionsContext = createContext({});

const useSectionsContext = () => useContext(SectionsContext);

const SectionsProvider = ({ children, translate }) => {
  const [sectionsState, setSectionsState] = useState([]);

  useEffect(() => {
    setSectionsState([
      {
        id: 1,
        sectionId: "section-start",
        text: translate("web_cognitiva_right_slide_01"),
        isFeatured: true
      },
      {
        id: 2,
        sectionId: "section-create",
        text: translate("web_cognitiva_right_slide_02"),
        isFeatured: false
      },
      {
        id: 3,
        sectionId: "section-aboutus",
        text: translate("web_cognitiva_right_slide_03"),
        isFeatured: true
      },
      {
        id: 4,
        sectionId: "section-solutions",
        text: translate("web_cognitiva_right_slide_04"),
        isFeatured: true
      },
      {
        id: 5,
        sectionId: "section-ournumbers",
        text: translate("web_cognitiva_right_slide_05"),
        isFeatured: false
      },
      {
        id: 6,
        sectionId: "section-team",
        text: translate("web_cognitiva_right_slide_06"),
        isFeatured: true
      },
      {
        id: 7,
        sectionId: "section-allies",
        text: translate("web_cognitiva_right_slide_07"),
        isFeatured: false
      },
      {
        id: 8,
        sectionId: "section-contact",
        text: translate("web_cognitiva_right_slide_08"),
        isFeatured: true
      }
    ]);
  }, [translate]);

  const getFeaturedSections = () => {
    return sectionsState.filter(s => s.isFeatured);
  };

  return (
    <SectionsContext.Provider
      value={{ all: sectionsState, featured: getFeaturedSections() }}
    >
      {children}
    </SectionsContext.Provider>
  );
};

export { SectionsProvider, useSectionsContext };
