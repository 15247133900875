import React from "react";

import { Logo } from "../components/Logo";
import { Nav } from "./Nav";

const Header = () => {
  return (
    <>
      <div className="head">&nbsp;</div>
      <header>
        <div className="wrapper flex">
          <Logo />
          <Nav />
        </div>
      </header>
    </>
  );
};

export default Header;
