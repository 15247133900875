import React from "react";

import logoIBM from "../assets/images/ibm.png";
import logoMicrosoft from "../assets/images/microsoft.png";
import logoSeidor from "../assets/images/seidor.png";

const OurAllies = ({ translate }) => {
  return (
    <div className="wrapper flex">
      <div className="title">{translate("web_cognitiva_allies_title")}</div>
      <div className="logo">
        <img src={logoIBM} alt="Logo IBM" />
      </div>
      <div className="logo">
        <img src={logoMicrosoft} alt="Logo Microsoft" />
      </div>
      <div className="logo">
        <img src={logoSeidor} alt="Logo Seidor" />
      </div>
    </div>
  );
};

export default OurAllies;
