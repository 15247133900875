module.exports = {
  pathPrefix: "./", // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "/portfolio"

  siteTitle: "etermax AI Labs", // Navigation and Site Title
  siteTitleAlt: "etermax AI Labs", // Alternative Site title for SEO
  siteTitleShort: "etermax AI Labs", // short_name for manifest
  siteHeadline: "etermax AI Labs", // Headline for schema.org JSONLD
  siteUrl: "https://ailabs.etermax.com", // Domain of your site. No trailing slash!
  siteLanguage: "en", // Language Tag on <html> element
  siteLogo: "/logo.png", // Used for SEO and manifest
  siteDescription:
    "Liberamos el potencial de la inteligencia artificialen los puntos de contacto entre las personas y las empresas.",
  author: "etermax AI Labs", // Author for schema.org JSONLD

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  userTwitter: "@cognitiva_el", // Twitter Username
  ogSiteName: "etermax.labs.cognitiva", // Facebook Site Name
  ogLanguage: "en_US", // Facebook Language
  googleAnalyticsID: "UA-131105908-1",

  // Manifest and Progress color
  themeColor: "#fff",
  backgroundColor: "blue",
  languages: ["en", "es"]
};
