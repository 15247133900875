import { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { documentObject } from '../globals/document';

const navRoot = documentObject.getElementById('mobile-nav-portal');

class MobileNavPortal extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired
  };

  constructor(props) {
    super(props);
    this.element = documentObject.createElement('div');
  }

  componentDidMount() {
    navRoot.appendChild(this.element);
  }

  componentWillUnmount() {
    navRoot.removeChild(this.element);
  }

  render() {
    const { children } = this.props;
    if (typeof document !== `undefined`) {
      return ReactDOM.createPortal(children, this.element);
    }
    return null;
  }
}

export { MobileNavPortal };
