import React from "react";
import iconTwitter from "../assets/images/twitter.svg";
import iconLinkedin from "../assets/images/linkedin.svg";

const SocialLinks = () => (
  <div className="social">
    <a href="https://twitter.com/etermax_ailabs" target="_blank">
      <img src={iconTwitter} alt="Twitter icon" />
    </a>
    <a href="https://www.linkedin.com/company/etermax-ailabs" target="_blank">
      <img src={iconLinkedin} alt="Linkedin icon" />
    </a>
  </div>
);

export { SocialLinks };
