import React, { useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

const Contact = ({ translate }) => {
  const { register, handleSubmit, errors, reset } = useForm();
  const [feedbackInfo, setFeedbackInfo] = useState({
    kind: "notSent",
    description: "",
  });

  const onSubmit = (data) => {
    const formData = JSON.stringify(
      {
        topic: "AI Labs",
        name: data.userName,
        email: data.userEmail,
        message: data.userText,
        subject: "Contacto por la web",
        submit: "Enviar"
      }
    )
    const url = "https://newsletter.itools.etermax.com/api/contact/info-ialabs";
    fetch(url, {
      method: "POST",
      headers: { 'Content-Type': 'application/json' },
      body: formData,
    })
      .then(() => {
        setFeedbackInfo({
          kind: "success",
          description: translate("web_cognitiva_contact_popup_sent_text"),
        });
      })
      .catch(() => {
        console.log("Error sending form");
        setFeedbackInfo({
          kind: "error",
          description: translate("web_cognitiva_contact_popup_error01_txt"),
        });
      })
      .then(() => {
        reset();
      });
  };

  return (
    <div className="wrapper">
      <div className="title">{translate("web_cognitiva_contact_title")}</div>
      <div className="form" onSubmit={handleSubmit(onSubmit)}>
        <form>
          <div className="name">
            <input
              name="userName"
              type="text"
              placeholder={translate("web_cognitiva_contact_company")}
              ref={register({
                required: true,
              })}
              style={
                errors.userName
                  ? { border: "2px solid red" }
                  : { border: "none" }
              }
            />
          </div>
          <div className="mail">
            <input
              name="userEmail"
              type="text"
              placeholder={translate("web_cognitiva_contact_email")}
              ref={register({ required: true })}
              style={
                errors.userEmail
                  ? { border: "2px solid red" }
                  : { border: "none" }
              }
            />
          </div>
          <textarea
            name="userText"
            placeholder={translate("web_cognitiva_contact_consultation")}
            ref={register({ required: true })}
            style={
              errors.userText ? { border: "2px solid red" } : { border: "none" }
            }
          ></textarea>
          <ErrorMessageContainer>
            {(errors.userName || errors.userEmail || errors.userText) && (
              <ErrorMessage>
                {translate("web_cognitiva_contact_popup_error_field")}
              </ErrorMessage>
            )}
          </ErrorMessageContainer>
          <SendFormContainer>
            {feedbackInfo && feedbackInfo.kind !== "notSent" && (
              <SendFormStatus status={feedbackInfo.kind}>
                {feedbackInfo.description}
              </SendFormStatus>
            )}
          </SendFormContainer>
          <div className="button">
            <button type="submit">
              {translate("web_cognitiva_contact_button")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

Contact.propTypes = {
  translate: PropTypes.func.isRequired,
};

const SendFormContainer = styled.div`
  margin-top: 20px;
`;

const SendFormStatus = styled.span`
  display: inline-block;
  background-color: ${({ status }) =>
    status === "error" ? "#dc3545" : "green"};
  color: #fff;
  padding: 10px;
`;

const ErrorMessageContainer = styled.div`
  margin-top: 20px;
`;

const ErrorMessage = styled.span`
  display: inline-block;
  background-color: #dc3545;
  color: #fff;
  padding: 10px;
`;

export { Contact };
