import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link as AnchorLink, animateScroll } from "react-scroll";
import styled from "@emotion/styled";

import { SocialLinks } from "../components/SocialLinks";
import { useSectionsContext } from "../context/SectionsContext";
import logo from "../assets/images/brand.svg";
import { Policy } from "./Policy";

const Footer = ({ shouldShowSectionLinks, translate }) => {
  const { featured: sections } = useSectionsContext();
  const [isPrivacyOpen, setIsPrivacyOpen] = useState(false);

  const privacyLinkClick = () => {
    setIsPrivacyOpen(currState => !currState);
    animateScroll.scrollToTop();
  };

  return (
    <footer>
      <div className="wrapper flex">
        <nav>
          <ul>
            {shouldShowSectionLinks &&
              sections.map(section => (
                <li key={section.id}>
                  <MenuLink
                    duration={500}
                    offset={-80}
                    smooth
                    to={section.sectionId}
                  >
                    {section.text}
                  </MenuLink>
                </li>
              ))}
          </ul>
        </nav>
        <div className="brand">
          <img src={logo} alt="Logo etermax AI Labs" />
        </div>
        <SocialLinks />
        <PrivacyAndRightsStatement>
          <PrivacyButtonLink onClick={privacyLinkClick}>
            {translate("web_cognitiva_bottom_privacy")}
          </PrivacyButtonLink>
          {isPrivacyOpen && (
            <Policy
              translate={translate}
              closeButtonClick={() => setIsPrivacyOpen(false)}
            />
          )}
          <p>{translate("web_cognitiva_bottom_rights")}</p>
        </PrivacyAndRightsStatement>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  shouldShowSectionLinks: PropTypes.bool,
  translate: PropTypes.func.isRequired
};

Footer.defaultProps = {
  shouldShowSectionLinks: true
};

const MenuLink = styled(AnchorLink)`
  cursor: pointer;
`;

const PrivacyAndRightsStatement = styled.div`
  color: #fff;
  font-size: 0.8rem;
  margin-top: 10px;
`;

const PrivacyButtonLink = styled.button`
  border: none;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  padding: 0;
`;

export { Footer };
