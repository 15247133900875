import React from "react";

const OurNumbers = ({ translate }) => {
  return (
    <div className="wrapper flex">
      <div className="title">
        {translate("web_cognitiva_our_numbers_title")}
      </div>
      <div className="data">
        <div className="number">
          {translate("web_cognitiva_our_numbers_card_01_title")}
        </div>
        {translate("web_cognitiva_our_numbers_card_01_desc")}
      </div>
      <div className="data">
        <div className="number">
          {translate("web_cognitiva_our_numbers_card_02_title")}
        </div>
        {translate("web_cognitiva_our_numbers_card_02_desc")}
      </div>
      <div className="data">
        <div className="number">
          {translate("web_cognitiva_our_numbers_card_03_title")}
        </div>
        {translate("web_cognitiva_our_numbers_card_03_desc")}
      </div>
    </div>
  );
};

export default OurNumbers;
