export const windowObject = (typeof window !== 'undefined' && window) || {
  innerHeight: 0,
  innerWidth: 0,
  scrollY: 0,
  addEventListener() {
    console.log('window.addEventListener');
  },
  removeEventListener() {
    console.log('window.removeEventListener');
  }
};
