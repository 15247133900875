import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Link } from "react-scroll";
import { DesktopNavPortal } from "../portals/DesktopNavPortal";
import { useScreenSize } from "../hooks/useScreenSize";
import { useScroll } from "../hooks/useScroll";
import { useSectionsContext } from "../context/SectionsContext";

const NavMenuDesktop = () => {
  const { all: sections } = useSectionsContext();
  const [isVisible, setVisibility] = useState(false);
  const { height } = useScreenSize();
  const { y } = useScroll();
  const heightWithOffset = height - 81;
  const shouldBeVisible = y > heightWithOffset;

  useEffect(() => setVisibility(shouldBeVisible));

  return (
    <DesktopNavPortal>
      <Layout>
        <Menu className={`ph4 ${isVisible ? "is-visible" : ""}`}>
          <List className="pr2">
            {sections.map(section => (
              <ListItem key={section.id}>
                <MenuLink
                  activeClass="is-active"
                  className="menu-link"
                  duration={500}
                  offset={-80}
                  smooth
                  spy
                  to={section.sectionId}
                >
                  <span>{section.text}</span>
                </MenuLink>
              </ListItem>
            ))}
          </List>
        </Menu>
      </Layout>
    </DesktopNavPortal>
  );
};

const Layout = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0;
  max-width: 1450px;
`;

const Menu = styled.div`
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  z-index: 100;
  top: 0;
  right: 0;
  display: none;
  justify-content: flex-end;
  align-items: center;
  height: 100vh;
  font-size: 1rem;
  pointer-events: none;
  transition: all 0.275s ease;
  &.is-visible {
    opacity: 1;
    visibility: visible;
    pointer-events: visible;
    &:hover .menu-link {
      pointer-events: all;
      span {
        opacity: 0.3;
      }
      &.is-active {
        span {
          opacity: 1;
        }
      }
    }
  }
  ${({ theme }) => `${theme.mq[1]} {
    display: inline-flex;
  }`}
  ${({ theme }) => theme.fonts.primary.normal};
`;

const List = styled.ul`
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 0;
  margin: 0;
`;

const ListItem = styled.li`
  display: block;
  padding: 0;
  margin: 0.8em 0;
  list-style: none;
`;

const MenuLink = styled(Link)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  color: ${({ theme }) => theme.colors.mountainMist};
  font-size: 0.65em;
  text-align: right;
  text-decoration: none;
  letter-spacing: 0;
  pointer-events: none;
  cursor: pointer;
  transition: all 0.275s ease;
  span {
    transition: all 0.275s ease;
    opacity: 0;
  }

  &:after {
    content: "";
    opacity: 0.3;
    display: block;
    margin-left: 0.8em;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.mountainMist};
    transition: transform 0.275s ease;
  }

  &.is-active:after {
    opacity: 1;
    background-color: ${({ theme }) => theme.colors.apple};
    transform: scale(1.8);
  }
`;

export { NavMenuDesktop };
