import { css } from "@emotion/core";

export const fonts = {
  primary: {
    normal: css`
      font-family: "Open Sans", sans-serif;
      font-weight: 400;
    `,
    bold: css`
      font-family: "Gotham", Arial, Helvetica, sans-serif;
      font-weight: 700;
    `,
    medium: css`
      font-family: "Gotham", Arial, Helvetica, sans-serif;
      font-weight: 500;
    `,
    black: css`
      font-family: "Gotham", Arial, Helvetica, sans-serif;
      font-weight: 900;
    `
  }
};
