import { useEffect } from "react";

const BotmakerWidget = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://go.botmaker.com/rest/webchat/p/OLNPFCZQSD/init.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null;
};

export default BotmakerWidget;