import React from "react";
import { Language } from "gatsby-plugin-i18next";
import styled from "@emotion/styled";

import languageIcon from "../assets/images/language.png";

const LangSwitch = ({ changeLng, lng }) => {
  return (
    <div>
      <LanguageMenuButton
        onClick={lng === "es" ? () => changeLng("en") : () => changeLng("es")}
      >
        <img
          src={languageIcon}
          width="18px"
          alt="Cambiar lenguaje/Change Language"
        />
      </LanguageMenuButton>
    </div>
  );
};

const LanguageMenuButton = styled.button`
  background-color: transparent;
  border: none;
  margin: 0 10px;
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    background-color: #494b4d;
  }
`;

export default props => (
  <Language>{lngProps => <LangSwitch {...props} {...lngProps} />}</Language>
);
