import { css } from "@emotion/core";

export default css`
  html {
    background-color: #28292b;
  }

  body {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 16px;
    background-color: #fff;
  }

  .wrapper {
    max-width: 1140px;
    margin: 0 auto;
  }

  .flex {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
  }

  .green {
    color: #9dec00;
  }

  .shadow {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }

  .bg-grey {
    background-color: #e4e4e4;
  }

  .bg-darkgrey {
    background-color: #353638;
  }

  .inicio {
    width: 100%;
    background-color: #28292b;
  }

  header {
    height: 80px;
    padding-top: 10px;
  }

  .head {
    width: 100%;
    height: 100px;
    background: linear-gradient(
      rgba(40, 41, 43, 1) 40%,
      rgba(40, 41, 43, 0) 100%
    );
    position: absolute;
  }

  .z {
    z-index: 1000;
  }

  .nav {
    margin-left: auto;
  }

  nav ul {
    list-style-type: none;
  }

  nav li {
    align-items: center;
    display: inline-block;
  }

  nav li a {
    margin: 0 20px;
    color: #fff;
    text-decoration: none;
  }
  nav li a:hover {
    margin: 0 20px;
    color: #9dec00;
    text-decoration: none;
  }

  .language-nav {
    margin-left: 15px;
    padding-top: 15px;
  }

  .language-nav ul.component {
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    display: inline-block;
  }

  .language-nav .icon {
    width: 20px;
    padding-bottom: 10px;
  }

  .language-nav .languages {
    display: none;
  }

  .language-nav ul li:hover .languages {
    padding: 0;
    background-color: #28292b;
    border: solid 1px #353638;
    position: absolute;
    top: 25px;
    right: 0;
    display: block;
    z-index: 1;
  }
  }

  .nav-mobile {
    display: none;
  }

  .inicio {
    margin-top: -80px;
  }

  .h1-box {
    max-width: 570px;
    margin-left: calc((100vw - 1155px) / 2);
    padding: 50px 50px 12px 0;
    font-family: "Gotham";
    font-size: 27px;
    color: #fff;
  }

  .h1-box h1 {
    margin-bottom: 0;
    font-size: 55px;
    font-weight: 900;
    text-transform: uppercase;
  }

  .h1-box p {
    margin-top: 10px;
  }

  .h1-box .chevrons {
    margin-top: 35px;
    text-align: center;
    display: block;
  }

  .video {
    width: 49%;
    height: 600px;
    overflow: hidden;
  }

  .video video {
    width: auto;
    height: 100%;
  }

  .inicio-creamos {
    padding: 60px 0 40px 0;
    background: linear-gradient(-5deg, #fff 27%, #e4e4e4 27%);
  }

  .inicio-creamos .inicio-creamos-text {
    width: 42%;
    line-height: 26px;
    padding-bottom: 60px;
  }

  .inicio-creamos .inicio-creamos-text h2 {
    font-size: 25px;
    line-height: 32px;
  }

  .inicio-creamos .blue-line {
    width: 150px;
    height: 8px;
    background-color: #0074d5;
  }

  .inicio-creamos .inicio-creamos-img {
    width: 50%;
  }

  .inicio-creamos .inicio-creamos-img img {
    width: 100%;
  }

  .nosotros {
    margin: 100px 0;
  }

  .nosotros .title {
    margin-bottom: 40px;
    font-family: "Gotham";
    font-size: 48px;
    font-weight: 900;
    text-align: center;
    text-transform: uppercase;
  }

  .nosotros .nosotros-box {
    width: 33.33%;
    padding: 30px 30px 30px 55px;
    line-height: 24px;
  }

  .nosotros .nosotros-box-img {
    margin-bottom: 25px;
  }

  .nosotros .nosotros-box-title {
    margin-bottom: 10px;
    font-family: "Gotham";
    font-size: 20px;
    font-weight: 700;
    display: block;
    text-transform: uppercase;
  }

  .soluciones {
    padding-bottom: 120px;
    background-color: #28292b;
  }

  .soluciones .back-image {
    width: 100%;
    height: 550px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% auto;
  }

  .soluciones .title {
    width: 770px;
    margin-top: -140px;
    margin-bottom: 40px;
    padding: 30px 85px;
    font-size: 18px;
    line-height: 24px;
    color: #fff;
    text-align: center;
    background-color: #0074d5;
  }

  .soluciones .title h3 {
    max-width: 570px;
    margin: 10px auto 20px auto;
    font-size: 48px;
    line-height: 58px;
    text-transform: uppercase;
  }

  .soluciones .title .white-line {
    width: 150px;
    height: 8px;
    margin: 0 auto;
    background-color: #fff;
  }

  .soluciones .soluciones-box {
    width: 48%;
    margin: 1%;
    padding: 30px 30px 30px 55px;
    line-height: 24px;
    color: #fff;
  }

  .soluciones .soluciones-box-img {
    margin-bottom: 25px;
  }

  .soluciones .soluciones-box-title {
    margin-bottom: 10px;
    font-family: "Gotham";
    font-size: 24px;
    font-weight: 700;
    display: block;
    text-transform: uppercase;
  }

  .soluciones-numeros {
    padding: 120px 0;
  }

  .soluciones-numeros .title {
    max-width: 45%;
    font-family: "Gotham";
    font-size: 40px;
    font-weight: 900;
    text-transform: uppercase;
  }

  .soluciones-numeros .data {
    font-family: "Gotham";
    font-size: 16px;
    font-weight: 700;
    text-align: center;
  }

  .soluciones-numeros .number {
    padding: 0 10px;
    font-family: "Gotham";
    font-size: 64px;
    font-weight: 900;
    text-align: center;
    color: #0074d5;
  }

  .equipo {
    padding: 120px 0 180px 0;
    background: linear-gradient(3deg, #fff 12%, #e4e4e4 12%);
  }

  .equipo .equipo-text {
    width: 50%;
    padding: 0 0 0 60px;
    font-size: 18px;
    line-height: 24px;
  }

  .equipo .equipo-text .blue-line {
    display: none;
  }

  .equipo .equipo-text p {
    margin-bottom: 30px;
  }

  .equipo .equipo-text .title {
    font-family: "Gotham";
    font-size: 42px;
    font-weight: 900;
    line-height: 50px;
    text-transform: uppercase;
  }

  .equipo .equipo-text-item {
    padding: 5px 0;
    border-bottom: solid 1px #adafaf;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .equipo .equipo-text-item .number {
    padding: 20px;
    font-family: "Gotham";
    font-size: 42px;
    font-weight: 900;
    color: #0074d5;
  }

  .equipo .equipo-text-item .description {
    font-size: 16px;
  }

  .equipo .equipo-img {
    width: 50%;
  }

  .equipo .equipo-img img {
    width: 100%;
  }

  .equipo-aliados {
    padding: 80px 0;
  }

  .equipo-aliados .title {
    max-width: 45%;
    font-family: "Gotham";
    font-size: 40px;
    font-weight: 900;
    text-transform: uppercase;
  }

  .contactanos {
    padding: 50px 0;
    background-color: #0074d5;
  }

  .contactanos .title {
    margin-bottom: 30px;
    font-family: "Gotham";
    font-size: 40px;
    font-weight: 900;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
  }

  .contactanos .form {
    max-width: 700px;
    margin: 0 auto;
  }

  .contactanos .form .name {
    width: 40%;
    padding-right: 15px;
    display: inline-block;
  }

  .contactanos .form .mail {
    width: 60%;
    display: inline-block;
  }

  input {
    width: 100%;
    margin-bottom: 15px;
    padding: 15px;
    border: 0;
    box-shadow: 2px 2px 5px 5px rgba(0, 0, 0, 0.1);
  }

  textarea {
    width: 100%;
    height: 100px;
    padding: 15px;
    border: 0;
    box-shadow: 2px 2px 5px 5px rgba(0, 0, 0, 0.1);
  }

  .contactanos .form .button {
    text-align: center;
  }

  .button button {
    margin-top: 20px;
    padding: 15px 30px;
    background-color: #28292b;
    text-transform: uppercase;
    border: 0;
    color: #fff;
    cursor: pointer;
  }

  .button button:hover {
    background-color: #494b4d;
  }

  footer {
    padding: 50px 0;
    background-color: #28292b;
  }

  footer nav ul {
    margin: 0;
    padding: 0;
  }

  footer nav ul li a,
  footer nav ul li a:hover {
    margin: 0 20px 0 0;
  }

  footer .social {
    width: 100%;
    margin-top: 10px;
  }

  footer .social img {
    margin-right: 10px;
  }

  #mobile-nav-portal .social img{
    margin-left: 10px;
  }

  @media screen and (max-width: 1140px) {
    .wrapper {
      padding: 0 15px;
    }

    .h1-box {
      width: 50%;
      margin: 0;
      padding: 50px 15px 12px 15px;
      font-family: "Gotham";
      font-size: 27px;
      color: #fff;
    }

    .video {
      width: 50%;
    }
  }

  @media screen and (max-width: 768px) {
    .wrapper {
      padding: 15px;
    }

    .brand img {
      max-width: 140px;
    }

    nav ul {
      width: 100%;
      padding: 0;
      background-color: #28292b;
      top: 80px;
      left: 0;
      position: absolute;
      display: none;
    }

    nav li {
      width: 100%;
      margin: 0;
      padding: 20px 0;
      text-align: center;
      border-bottom: solid 1px rgba(255, 255, 255, 0.05);
    }

    nav li a {
      text-decoration: none;
    }

    .nav-mobile {
      display: inline-block;
    }

    .language-nav {
      position: absolute;
      top: 20px;
      right: 60px;
    }

    .inicio .video,
    .head {
      display: none;
    }

    .h1-box {
      width: 100%;
      max-width: 100%;
      margin: 0;
      padding: 110px 15px 15px 15px;
      font-size: 18px;
    }

    .h1-box h1 {
      font-size: 36px;
    }

    .h1-box .chevrons {
      margin-top: 55px;
    }

    .inicio-creamos {
      background: linear-gradient(-5deg, #fff 5%, #e4e4e4 5%);
    }

    .inicio-creamos .inicio-creamos-text {
      width: 100%;
      order: 2;
      padding-bottom: 0px;
    }

    .inicio-creamos .inicio-creamos-text .blue-line {
      margin: 10px 0 40px 0;
    }

    .inicio-creamos .inicio-creamos-img {
      width: 100%;
      order: 1;
    }

    .nosotros {
      margin: 40px 0 25px 0;
    }

    .nosotros .title {
      font-size: 32px;
    }

    .nosotros .nosotros-box {
      width: 100%;
      padding: 30px;
    }

    .soluciones {
      padding: 40px 0 25px 0;
      background-color: #28292b;
    }

    .soluciones .back-image {
      display: none;
    }

    .soluciones .title {
      width: 90%;
      margin-top: 0;
      margin-bottom: 20px;
      padding: 30px;
    }

    .soluciones .title h3 {
      font-size: 32px;
      line-height: 40px;
    }

    .soluciones-numeros {
      padding: 40px 0 10px 0;
    }

    .soluciones-numeros .title {
      max-width: 100%;
      margin-bottom: 30px;
      font-size: 32px;
      text-align: center;
    }

    .soluciones-numeros .data {
      width: 100%;
      margin-bottom: 30px;
      text-align: center;
    }

    .soluciones-numeros .number {
      padding: 0 10px;
      font-family: "Gotham";
      font-size: 64px;
      font-weight: 900;
      text-align: center;
      color: #0074d5;
    }

    .soluciones .soluciones-box {
      width: 100%;
      margin-bottom: 20px;
      padding: 30px;
      background-color: #353638;
    }

    .equipo {
      padding: 40px 0 70px 0;
      background: linear-gradient(3deg, #fff 5%, #e4e4e4 5%);
    }

    .equipo .equipo-text {
      width: 100%;
      padding: 30px 0 0 10px;
    }

    .equipo .equipo-text .title {
      font-size: 32px;
      line-height: 40px;
    }

    .equipo .equipo-text .blue-line {
      width: 150px;
      height: 8px;
      margin: 10px 0 40px 0;
      background-color: #0074d5;
      display: block;
    }

    .equipo .equipo-img {
      width: 100%;
    }

    .equipo .equipo-text-item .number {
      padding-left: 0;
    }

    .equipo .number4 {
      border-bottom: 0;
    }

    .equipo-aliados {
      padding: 0;
    }

    .equipo-aliados .title {
      width: 100%;
      max-width: 100%;
      margin-bottom: 20px;
      font-size: 32px;
      line-height: 40px;
      text-align: center;
    }

    .equipo-aliados .logo {
      width: 100%;
      margin: 20px 0;
      text-align: center;
    }

    .equipo-aliados .logo img {
      max-width: 50%;
    }

    .contactanos {
      padding: 40px 0;
    }

    .contactanos .title {
      font-size: 32px;
    }

    .contactanos .form .name,
    .contactanos .form .mail {
      width: 100%;
      padding-right: 0;
    }

    textarea {
      height: 150px;
    }

    footer .brand {
      width: 100%;
      text-align: center;
    }

    footer .social {
      margin-top: 30px;
      text-align: center;
    }

    footer .social img {
      margin: 0 10px;
    }
  }
`;
