import React from "react";
import PropTypes from "prop-types";

import conversacionesNaturalesIcon from "../assets/images/conversaciones-naturales.svg";
import nuevoConocimientoIcon from "../assets/images/nuevo-conocimiento.svg";
import automatizacionInteligenteIcon from "../assets/images/automatizacion-inteligente.svg";

const AboutUs = ({ translate }) => {
  return (
    <div className="wrapper">
      <div className="title">{translate("web_cognitiva_about_us_title")}</div>
      <div className="flex shadow">
        <div className="nosotros-box">
          <div className="nosotros-box-img">
            <img
              src={conversacionesNaturalesIcon}
              alt="Imagen conversaciones naturales"
            />
          </div>
          <span className="nosotros-box-title">
            {translate("web_cognitiva_about_us_card_01_title")}
          </span>
          {translate("web_cognitiva_about_us_card_01_desc")}
          <b>{translate("web_cognitiva_about_us_card_01_desc_highlight")}</b>
        </div>
        <div className="nosotros-box bg-grey">
          <div className="nosotros-box-img">
            <img src={nuevoConocimientoIcon} alt="Imagen nuevo conocimiento" />
          </div>
          <span className="nosotros-box-title">
            {translate("web_cognitiva_about_us_card_02_title")}
          </span>
          {translate("web_cognitiva_about_us_card_02_desc_01")}
          <b>{translate("web_cognitiva_about_us_card_02_desc_highlight")}</b>
          {translate("web_cognitiva_about_us_card_02_desc_02")}
        </div>
        <div className="nosotros-box">
          <div className="nosotros-box-img">
            <img
              src={automatizacionInteligenteIcon}
              alt="Imagen automatizacion inteligente"
            />
          </div>
          <span className="nosotros-box-title">
            {translate("web_cognitiva_about_us_card_03_title")}
          </span>
          <b>{translate("web_cognitiva_about_us_card_03_desc_highlight")}</b>
          {translate("web_cognitiva_about_us_card_03_desc")}
        </div>
      </div>
    </div>
  );
};

AboutUs.propTypes = {
  translate: PropTypes.func.isRequired
};

export default AboutUs;
