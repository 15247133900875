import React from "react";
import PropTypes from "prop-types";

import creamosSolucionesImg from "../assets/images/creamos-soluciones.png";

const Cognitiva = ({ translate }) => (
  <div className="wrapper flex">
    <div className="inicio-creamos-text">
      <h2>{translate("web_cognitiva_inicio_creamos_title")}</h2>
      <div className="blue-line">&nbsp;</div>
      <p>
        {translate("web_cognitiva_inicio_creamos_desc_01")}
        <br></br>
        {translate("web_cognitiva_inicio_creamos_desc_02")}
      </p>
    </div>
    <div className="inicio-creamos-img">
      <img src={creamosSolucionesImg} alt="Imagen creamos soluciones" />
    </div>
  </div>
);

Cognitiva.propTypes = {
  translate: PropTypes.func.isRequired
};

export { Cognitiva };
