import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { Link } from "react-scroll";
import { useSectionsContext } from "../context/SectionsContext";
import { MobileNavPortal } from "../portals/MobileNavPortal";
import { SocialLinks } from "./SocialLinks";

const NavMenuMobile = ({ isOpen = false, closeNavMenu }) => {
  const { all: sections } = useSectionsContext();
  return (
    <MobileNavPortal>
      <div>
        <Menu isOpen={isOpen}>
          <List>
            {sections.map(section => (
              <ListItem key={section.id} quantity={sections.length}>
                <MenuLink
                  activeClass="is-active"
                  duration={500}
                  offset={-110}
                  onClick={() => closeNavMenu()}
                  smooth
                  spy
                  to={section.sectionId}
                >
                  {section.text}
                </MenuLink>
              </ListItem>
            ))}
          </List>
          <PanelDivider />
          <SocialLinks />
        </Menu>
      </div>
    </MobileNavPortal>
  );
};

NavMenuMobile.propTypes = {
  closeNavMenu: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

const ListItem = styled.li`
  display: block;
  padding: 0;
  margin: 0.5em 0;
  list-style: none;
  opacity: 0;
  transform: translateX(10%);
  ${({ quantity }) => {
    let style = ``;
    for (let i = 1; i <= quantity; i += 1) {
      style += `
        &:nth-child(${i}) {
          transition: all 275ms ${125 + 50 * i}ms;
        }
      `;
    }
    return style;
  }}
`;

const Menu = styled.div`
  overflow: scroll;
  position: fixed;
  z-index: 550;
  top: 58px;
  padding-top: 1rem;
  padding-right: 3rem;
  padding-bottom: 6rem;
  width: 100vw;
  height: 400px;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.black};
  font-size: 1rem;
  text-align: right;
  opacity: 0;
  visibility: hidden;
  transform: translateX(5%);
  transition-property: transform, opacity, visibility;
  transition-duration: 0.375s, 0.275s, 0.275s;
  transition-timing-function: ease;

  ${({ isOpen }) =>
    isOpen &&
    css`
      opacity: 1;
      visibility: visible;
      transform: translateX(0);
      ${ListItem} {
        opacity: 1;
        transform: translateX(0);
      }
    `}
`;

const List = styled.ul`
  padding: 0;
  margin: 0;
`;

const MenuLink = styled(Link)`
  display: block;
  padding: 12px 0;
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.2em;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.275s ease;
  &.is-active {
    opacity: 1;
  }
`;

const PanelDivider = styled.div`
  width: 35px;
  height: 1px;
  display: inline-block;
  margin: 20px 0;
  background-color: ${({ theme }) => theme.colors.white};
`;

export { NavMenuMobile };
